@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #e4d5be;
  --secondary: #796f61;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("/public/Fonts/PPNeueMontreal-Book.otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("/public/Fonts/PPNeueMontreal-Medium.otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  /* width: 50px;
  height: 50px; */
  background: url("/public/newMenuAssets/slider-icon.png") no-repeat;
  /* transform: scale(0.15); */
  border-radius: 0 !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 99px;
  background-color: var(--primary);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--primary);
}

::-webkit-scrollbar-thumb {
  border-radius: 99px;
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: var(--secondary);
}
